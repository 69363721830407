import React, { useEffect, useContext } from 'react';

import Menu from "./components/Menu/Menu";
import Spotify from "./components/Spotify/Spotify";
import TripleJ from "./components/TripleJ/TripleJ";
import Hottest100 from "./components/Hottest100/Hottest100";
import Home from "./components/Home";
import Companions from './components/Companions/Companions';
import Page404 from './components/Page404';
import Feedback from './components/Feedback';
import Logout from './components/Logout';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { TokenContext } from './components/Providers/TokenProvider';

import "./style/main.css"


function App() {

  const [ token, setToken ] = useContext(TokenContext);
  const [ cookies, setCookie ] = useCookies(['token']);

  useEffect(() => {

    const hash = window.location.hash;
    const existingToken = cookies.token;

    if (!existingToken && hash) {
      const newToken = hash.substring(1).split("&").find(e => e.startsWith("access_token")).split("=")[1];
      setToken(newToken);
      setCookie('token', newToken, { maxAge: 1 * 60 * 60, path: "/", secure: true, sameSite: true });
      return;
    }

    if (token !== existingToken) setToken(existingToken);

  });

  return (      
    <Router>
      <div id="content">
        <Menu />

        <Routes>
          <Route path='/' element={<Home />}> </Route>
          <Route path='/spotify/*' element={<Spotify />}> </Route>
          <Route path='/hottest-100/*' element={<Hottest100 />}> </Route>
          <Route path='/triple-j/*' element={<TripleJ />}> </Route>
          <Route path='/companions/*' element={<Companions />}> </Route>
          <Route path='/feedback/*' element={<Feedback />}> </Route>
          <Route path='/feedback/:type/*' element={<Feedback />}> </Route>
          <Route path='/logout/*' element={<Logout />}> </Route>
          <Route path='/logout/:type/*' element={<Logout />}> </Route>
          <Route path='/*' element={<Page404 />}></Route>
        </Routes>

      </div>
    </Router>
  );
}

export default App;
