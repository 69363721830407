import React, { useState } from 'react';

import { Header, ErrorMessage, MultipleImageUploader, TextInput } from './Common';

import '../style/feedback.css';
import { apiAddFeedbackItem } from '../services/functionApp';
import { useParams, useNavigate } from 'react-router-dom';


const Feedback = () => {

  const [ name, setName ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ description, setDescription ] = useState("");
  const [ images, setImages ] = useState([]);
  const [ validation, setValidation ] = useState(false);
  const { type } = useParams();
  const nav = useNavigate();

  const [ errorContent, setErrorContent ] = useState({});
  const [ loadError, setLoadError ] = useState(false);

  const addImage = (image) => { setImages([ ...images, image]); }

  const removeImage = (index) => { 
    const copy = [ ...images ];
    copy.splice(index, 1);
    setImages(copy); 
  }

  const processFeedbackForm = async () => {
    setValidation(true);
    if (name === "" || email === "" || description === "") return;

    //If we get here form has been validated
    const feedbackItem = {feedbackItem: { feedbackId: Date.now(), name, email, description, images }};
    const response = await apiAddFeedbackItem(feedbackItem);

    if (!response || response.error) {
      setLoadError(true);
      setErrorContent({
        header: "we've encountered a technical issue",
        subHeader: "sorry, we've been unable to process your feedback request at this time. please try again later",
        imageSource: "warning.png",
        closable: false,
        setShow: setLoadError
      });
      return;
    }

    //If we get here, the request feedback was submitted successfully
    nav('/feedback/successful');


  }

  if (type === "successful") {
    return (
      <div>
        <Header 
          header="feedback"
          subHeader="please fill out the form below to submit feedback to the developer" />  
        <div data-test='feedback-sent-message' className='message-box b-accept'>
          <div className="fr ac margin-top">
            <img className="thumb-mini pad-right" src={require("../images/gmail.png")} alt="companions logo"></img>
            <h1 className="no-margin">we've got your feedback</h1>
          </div>
          <p className='margin-top'>thanks for your valuable feedback, we'll be in touch shortly!</p>
        </div>
      </div>
    )
  }

  return (
    <div id="feedback-form">

      <Header 
        header="feedback"
        subHeader="please fill out the form below to submit feedback to the developer"
      />      
      { loadError && <ErrorMessage data={errorContent} /> }

      <TextInput label="name:" placeholder="name" setState={setName} validation={validation} />
      <TextInput label="email:" placeholder="email" setState={setEmail} validation={validation} />
      <TextInput label="description:" placeholder="description" setState={setDescription} validation={validation} textBox={true} />

      <MultipleImageUploader images={images} addImage={addImage} removeImage={removeImage} />
      

      <button className="btn send-feedback" onClick={processFeedbackForm}>Send Feedback</button>

    </div>

  )
};

export default Feedback;