import React, { useContext, useState } from 'react';

import { Modal, Alert, ErrorMessage, Loader } from '../Common';
import TimeFrame from './Timeframe';
import TopRenderer from './TopRenderer';

import { Navigate, useParams } from 'react-router-dom';
import { TokenContext } from '../Providers/TokenProvider';
import { apiUpdateTrackShortlist } from '../../services/functionApp';

import '../../style/top.css';

const toggleItems = ["short", "medium", "long"];
const types = [ "tracks", "artists" ];

const Top = () => {
  
  const [ token ] = useContext(TokenContext);
  const { time, type } = useParams();

  const [ showModal, setShowModal ] = useState(false);
  const [ modalContent, setModalContent] = useState({});

  const [ alertVisible, setAlertVisible ] = useState(false);
  const [ alertContent, setAlertContent ] = useState({});

  const [loaded, setLoaded] = useState(true);
  const [topData, setTopData] = useState({ tracks: {}, artists: {} });
  const [ loadError, setLoadError ] = useState(false);
  const [ errorContent, setErrorContent ] = useState({});

  const updateAlert = (alert, added) => {
    setAlertContent({
      imageSource: alert.album.images[1].url,
      title: alert.name,
      artist: alert.artists[0].name,
      added
    });
    setAlertVisible(true);
    setTimeout(() => setAlertVisible(false), 3000);
  }

  const triggerShortlistModal = (index) => {
    const data = {
      imageSource: topData["tracks"][time][index - 1].album.images[1].url,
      name: topData["tracks"][time][index - 1].name
    }

    setModalContent({
      data,
      callbackPayload: index,
      add: true,
      confirmCallback: addTrackToShortlist
    });
    setShowModal(true);
  }

  const addTrackToShortlist = async (index) => {

    const trackData = topData["tracks"][time][index - 1];
    const response = await apiUpdateTrackShortlist(token, trackData);
    if (response.error) {
      setLoadError(true);
      setErrorContent({
        header: "we're sorry",
        subHeader: "we encountered a technical issue when trying to add that track to your shortlist",
        imageSource: "warning.png",
        closable: true,
        setShow: setLoadError
      });
      return;
    }

    //Deep copy state to modify added value for track just added
    const json = JSON.stringify(topData);
    let topDataCopy = JSON.parse(json);
    topDataCopy["tracks"][time][index-1].added = true;
    setTopData(topDataCopy);
    updateAlert(trackData, true);

  }


  if (!toggleItems.includes(time) || !types.includes(type)) {
    return ( <Navigate replace to="/404" /> );
  }
  

  if (!loaded) {
    return (
      <div>
        <TimeFrame toggleItems={toggleItems} />
        <Loader />
      </div>
    )
  }

  return (

    <div>

      <TimeFrame toggleItems={toggleItems} />
      { loadError && <ErrorMessage data={errorContent} /> }
      <Modal show={showModal} setShow={setShowModal} content={modalContent} />
      <Alert data={alertContent} visible={alertVisible} />
      <div id="top-tracks" className="fc">
        <TopRenderer topData={topData} setTopData={setTopData} 
          triggerShortlistModal={triggerShortlistModal} setLoaded={setLoaded}
          setLoadError={setLoadError} setErrorContent={setErrorContent} loadError={loadError} />
      </div>
    </div>
  )
};


export default Top;