import React from 'react';

import { ToggleSelector } from '../Common';
import MostPlayedArchive from './MostPlayedArchive';
import Hottest100Archive from './Hottest100Archive';

import { Navigate, useParams } from 'react-router-dom';

const types = ["most-played", "hottest-100"];

const ArchiveSwitcher = ({props}) => {

  let { type } = useParams();

  return (
    <div>
      <ToggleSelector toggleItems={["most played", "hottest 100"]} 
      className="toggle-selector archive-toggle" value={type} />
      {type === "most-played" && <MostPlayedArchive />}
      {type === "hottest-100" && <Hottest100Archive />}
      {!types.includes(type) && <Navigate replace to="/404" />}
    </div>

  )
};

export default ArchiveSwitcher;