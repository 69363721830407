import React from 'react';

import { Header } from './Common';
//import { CompanionAlert } from './Common';

import { useNavigate } from 'react-router-dom';

import '../style/home.css';


const Home = () => {

  let nav = useNavigate();

  const goToShortlistPage = () => nav("/hottest-100/shortlist");

  return (
    <div>

      {/* <CompanionAlert /> */}

      <Header 
        header="welcome to urpix"
        subHeader="a project developed out of passion for the australian music scene"
      />

      <div id="section-breaker">
        <img src={require(`../images/jjjflowers.png`)} alt="triple-j flowers"></img>
      </div>

      <p className="light-text">this website was developed to make it easy to create and manage your shortlist for triple j's hottest 100 countdown</p>

      <p className="light-text">have a look around to see your top tracks on spotify, or what's popular on triple j at the moment </p>

      <p className="light-text">why not get started now?</p>

      <button style={{padding: "0.2em 0.5em", marginBottom: "1em"}} 
          className="btn" onClick={goToShortlistPage}>create my shortlist</button>

      {/* <Header 
        header="contact"
        subHeader="if you have any feature requests or want to view my other work feel free to reach out."
      />


      <div className="fc">
        {socials.map((item, i) => {
          return (
            <div key={i} className="contact-panel fr ac">
              <img src={require(`../images/${item.image}`)} alt={item.name}></img>
              <p>{item.text}</p>
            </div>
        )})}
      </div> */}

    </div>

  )
};

export default Home;