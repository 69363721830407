import React, { useState, useEffect, useContext } from 'react';

import { Header, Loader, Modal, Alert, AuthWarning, ErrorMessage } from '../Common';
import RecentlyPlayed from './RecentlyPlayed';

import { getTripleJRecentlyPlayed } from '../../services/abc';
import { TokenContext } from '../Providers/TokenProvider';
import { checkTrackEligibility, getABCImageSource, 
  getABCReleaseYear, addTrackFromTripleJ } from '../../services/helpers';

import '../../style/triple-j.css';

const Playing = ({shortlist, setShortlist}) => {

  const [ token ] = useContext(TokenContext);

  const [ lastTimeStamp, setLasttimeStamp ] = useState(new Date().toISOString());
  const [ playedTracks, setPlayedTracks ] = useState([]);
  const [ loaded, setLoaded] = useState(false);

  const [ showModal, setShowModal ] = useState(false);
  const [ modalContent, setModalContent] = useState({});

  const [ alertVisible, setAlertVisible ] = useState(false);
  const [ alertContent, setAlertContent ] = useState({});

  const [ errorContent, setErrorContent ] = useState({});
  const [ loadError, setLoadError ] = useState(false);

  const updateAlert = (alert) => {
    setAlertContent({
      imageSource:  getABCImageSource(alert),
      title: alert.recording.title,
      artist: alert.recording.artists[0].name,
      added: true
    });
    setAlertVisible(true);
    setTimeout(() => setAlertVisible(false), 3000);
  }

  const checkInShortlist = (track, shortlistTrack) => {
    return shortlistTrack.trackName === track.recording.title &&
            shortlistTrack.artistName === track.recording.artists[0].name
  }

  const CheckShortlistAdded = (playing) => {
    let hasPlayed = false;
    if (!shortlist) return false;
    for (let shortlistTrack of shortlist) {
      if (checkInShortlist(playing, shortlistTrack)) return true;
    }
    return hasPlayed;
  }


  
  const triggerModalAdd = (index) => {
    const data = {
      imageSource: getABCImageSource(playedTracks[index]),
      name: playedTracks[index].recording.title
    }

    setModalContent({
      data,
      callbackPayload: index,
      add: true,
      confirmCallback: addTripleJTrackToShortlist
    });
    setShowModal(true);
  }

  const addTripleJTrackToShortlist = async (index) => {
    const response = await addTrackFromTripleJ(token, 
      playedTracks[index].recording.title, playedTracks[index].recording.artists[0].name);

    if (response.error) {
      const content = response.matchError 
        ? "the track couldn't be matched on spotify. please try adding via the shortlist section"
        : "we encountered a technical issue when trying to add that track to your shortlist";
      setLoadError(true);
      setErrorContent({
        header: "we're sorry",
        subHeader: content,
        imageSource: "warning.png",
        closable: true,
        setShow: setLoadError
      });
      return;
    }

    //Add new track to the shortlist on FED side
    const clone = shortlist ? JSON.parse(JSON.stringify(shortlist)) : [];
    clone.push({
      id: response.id, 
      index: clone.length, 
      dateAdded: Date.now(),
      uri: response.uri,
      audioSrc: response.preview_url,
      spotifyUrl: response.external_urls.spotify,
      imageSrc: response.album.images[1].url,
      releaseDate: response.album.release_date,
      trackName: response.name,
      artistName: response.artists[0].name
    });
    setShortlist(clone);

    setShowModal(false);
    setLoadError(false);
    updateAlert(playedTracks[index]);
  }

  useEffect(() => {
    if (loaded) return;

    async function initialLoad() {
      const response = await getTripleJRecentlyPlayed(lastTimeStamp);
      if (response.error) {
        setLoaded(true);
        setLoadError(true);
        setErrorContent({
          header: "we're sorry",
          subHeader: "we couldn't load the most recent tracks from triple j",
          imageSource: "warning.png",
          closable: true,
          setShow: setLoadError
        });
        return;
      }
      const { items, stamp } = response;
      let tracks = Array.from(items);
      setPlayedTracks(tracks);
      setLoaded(true);
      setLasttimeStamp(stamp);
    }
    initialLoad();
  })

  const loadMoreTracks = async () => {

    //Load 30 minutes before last time stamp
    const { items, stamp } = await getTripleJRecentlyPlayed(lastTimeStamp);
    let tracks = Array.from(playedTracks);
    tracks = tracks.concat(items);
    setPlayedTracks(tracks);
    setLasttimeStamp(stamp);
  }


  const renderRecentlyPlayed = () => {

    return playedTracks.map((item, i) => {

      const formattedTime = new Date(item.played_time).toLocaleTimeString('en-US', {
        // en-US can be set to 'default' to use user's browser settings
        hour: '2-digit',
        minute: '2-digit',
      }).replace(" ", "").toLowerCase();

      if (!item.added) item.added = CheckShortlistAdded(item);
      let imageUrl = getABCImageSource(item);

      let releaseYear = getABCReleaseYear(item)
      const eligibility = checkTrackEligibility(releaseYear);

      return (
        <RecentlyPlayed imageSource={imageUrl} index={i} 
          artist={item.recording.artists[0].name} key={i}
          time={formattedTime} 
          title={item.recording.title}
          eligibility={eligibility} 
          addToShortlist={triggerModalAdd}
          added={item.added} />
      )
    })

  }

  return (
    <div>
      <Header 
        header="playing now on triple j"
        subHeader="see what's playing live on triple j right now"
      />

      {!token && <AuthWarning redirectEndpoint={"triple-j/playing"} />}

      { loadError && <ErrorMessage data={errorContent} /> }
      <Alert data={alertContent} visible={alertVisible} />
      <Modal show={showModal} setShow={setShowModal} content={modalContent} />

      <div id="top-tracks" className="fc">
        {!loaded && <Loader />}
        {loaded && renderRecentlyPlayed() }
        {loaded && !loadError && 
          <div id="load-holder" className="fr ac jc">
            <button className="btn" id="load-more" onClick={loadMoreTracks}>load more</button> 
          </div>
        }
      </div>
    </div>
    
    
  )
};



export default Playing;