import React, { useState, useEffect } from 'react';

import { Header, Loader, Alert, AuthWarning, Modal, ErrorMessage } from '../Common';
import TrendingTrack from './TrendingTrack';
import { checkTrackEligibility } from '../../services/helpers';
import { Link } from 'react-router-dom';
import { apiGetRecentlyShortlisted, apiUpdateShortlist } from '../../services/functionApp';
import { fetchShortlist } from '../../services/helpers';


const Trending = ({ shortlist, setShortlist, token }) => {

  const [ tracks, setTracks ] = useState({});
  const [ loaded, setLoaded ] = useState(false);

  const [ alertVisible, setAlertVisible ] = useState(false);
  const [ alertContent, setAlertContent ] = useState({});

  const [ showModal, setShowModal ] = useState(false);
  const [ modalContent, setModalContent] = useState({});

  const [ errorContent, setErrorContent ] = useState({});
  const [ loadError, setLoadError ] = useState(false);

  useEffect(() => {
    async function getTrending() {
      const response = await apiGetRecentlyShortlisted();
      if (!response || response.error) {
        setLoadError(true);
        setErrorContent({
          header: "we're sorry",
          subHeader: "we encountered a technical issue when trying find trending tracks. please try again later",
          imageSource: "warning.png",
          closable: false,
          setShow: setLoadError
        });
        setLoaded(true);
      } else {
        setTracks(response);
        setLoaded(true);
      }

    }
    getTrending();
  }, [shortlist, token]);

  const containsTracks = (item) => {
    if (shortlist && shortlist.tracks) {
      for (let track of shortlist.tracks) {
        if (track.id === item.id) return true;
      }
    }
    return false;
  }

  
  const updateAlert = (alert, added) => {
    setAlertContent({
      imageSource: alert.imageSrc,
      title: alert.trackName,
      artist: alert.artistName,
      added
    });
    setAlertVisible(true);
    setTimeout(() => setAlertVisible(false), 3000);
  }

  const getTrackById = (type, index) => {
    if (type === "user-added") return tracks.users[index];
    return tracks.tripleJ[index];
  }

  const triggerModalAdd = (info) => {

    const data =  {
      imageSource: info.item.imageSrc,
      name: info.item.trackName
    };

    setModalContent({
      data,
      callbackPayload: info,
      add: true,
      confirmCallback: addTrackToShortlist
    });
    setShowModal(true);
  }

  const addTrackToShortlist = async (id) => {
    const trackData = getTrackById(id.type, id.index);
    const newShortlist = shortlist?.tracks ? Array.from(shortlist.tracks) : [];

    newShortlist.push({
      id: trackData.id,
      index: trackData.index, 
      dateAdded: Date.now(),
      uri: trackData.uri,
      audioSrc: trackData.audioSrc,
      spotifyUrl: trackData.spotifyUrl,
      imageSrc: trackData.imageSrc,
      releaseDate: trackData.releaseDate,
      trackName: trackData.trackName,
      artistName: trackData.artistName
    });
    
    const response = await apiUpdateShortlist(token, newShortlist);
    if (trackData === null || response.error) { 
      setLoadError(true);
      setErrorContent({
        header: "we're sorry",
        subHeader: "we encountered a technical issue when trying to add that track to your shortlist",
        imageSource: "warning.png",
        closable: true,
        setShow: setLoadError
      });
      return;
    }

    //The first time they're adding a song, shortlist won't be in memory
    if (!shortlist)  {
      const response = await fetchShortlist(token);
      setShortlist(response.shortlistItems)
      updateAlert(trackData, true);
      return;
    }

    const clone = JSON.parse(JSON.stringify(shortlist));
    clone.tracks = newShortlist;
    setShortlist(clone);
    updateAlert(trackData, true);
  }

  const renderUserAdded = () => {
    if (!tracks.users || tracks.users.length === 0) {
      return (
        <div data-test='no-user-added-message' className='message-box b-info'>
          <p className='margin-top'>no tracks recently shortlisted. once users start adding tracks to their shortlist they will show up here</p>
          <Link className="fr ac" to="/hottest-100/shortlist">
            <img className="thumb-mini pad-right" src={require("../../images/info.png")} alt="companions logo"></img>
            <p className="bold">view shortlist</p>
          </Link>  
        </div>
      );
    }

    return tracks.users.map((item, i) => {
        return (
          <TrendingTrack title={item.trackName} artist={item.artistName} 
            isEligible={checkTrackEligibility(item.releaseDate)} imageSource={item.imageSrc}
            audioSource={item.audioSrc} ranking={i+1} token={token}
            spotifyUrl={item.SpotifyUrl} key={i} added={containsTracks(item)} id={item.id} 
            addToShortlist={() => triggerModalAdd({type: "user-added", index: i, item})}
            />
        )
    })
  }

  const renderTripleJAdded = () => {
    if (!tracks.tripleJ || tracks.tripleJ.length === 0) {
      return (
        <div data-test='no-triplej-popular-message' className='message-box b-info'>
          <p className='margin-top'>no popular tracks from triple j yet. once we've collected the most popular tracks playing recently they will appear here</p>
          <Link className="fr ac" to="/triple-j/popular-now">
            <img className="thumb-mini pad-right" src={require("../../images/info.png")} alt="companions logo"></img>
            <p className="bold">view triple j popular now</p>
          </Link>  
        </div>
      );
    }

    return tracks.tripleJ.map((item, i) => {
      return (
        <TrendingTrack title={item.trackName} artist={item.artistName} 
          isEligible={checkTrackEligibility(item.releaseDate)} imageSource={item.imageSrc}
          audioSource={item.audioSrc} ranking={i+1} token={token}
          spotifyUrl={item.spotifyUrl} key={i} added={containsTracks(item)} id={item.id}
          addToShortlist={() => triggerModalAdd({type: "triple-j", index: i, item})}
        />
      )
  })
  }

  if (!loaded) return (
    <div>      
      <Header 
        header="what's trending right now?"
        subHeader="see tracks being shortlisted by other users now and eligible tracks that triple j have recently played"
      />
      <Loader />
    </div>
  )


  return (
    <div>
      <Header 
        header="what's trending right now?"
        subHeader="see tracks being shortlisted by other users now and eligible tracks that triple j have recently played"
      />
      { loadError && <ErrorMessage data={errorContent} /> }

      {!loadError && 
      <>
        {!token && <AuthWarning redirectEndpoint={"hottest-100/trending"} />}
        <h4 className="sub-head">recently added by users</h4>
        <div id="userAddedTracks" className="pastTopTracks">
          { loaded && renderUserAdded() }
        </div>
        <h4 className="sub-head">popular on triple j</h4>      
        <div id="tripleJPopular" className="pastTopTracks">
          { loaded && renderTripleJAdded() }
        </div>
      </>
      }


      <Alert data={alertContent} visible={alertVisible} />
      <Modal show={showModal} setShow={setShowModal} content={modalContent} />
    </div>

  )
};

export default Trending;